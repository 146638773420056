





















import { Component, Prop, Vue } from "vue-property-decorator";
import { DemandSupplyStore } from "@/store/modules";

@Component
export default class SeedTypeDetail extends Vue {
  @Prop({ default: "" })
  seedType!: string;

  @Prop({ default: [] })
  data!: any[];
}
