


























































import { Vue, Component, Prop } from "vue-property-decorator";
import Listingbody from "@/components/UIComponents/Listingbody.vue";
import {
  CropStore,
  CompareCropStore,
  CropVarietyStore,
  LoadStore,
  MetaStore
} from "@/store/modules";
import CropVarietyValue from "./Admin/CropVarietyValue.vue";
import { constants } from "crypto";
import { MetaInfo } from "vue-meta";

@Component({
  components: {
    Listingbody
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$data.title,
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: this.$data.title },
        {
          name: "description",
          content: "List of varieties of " + this.$data.title,
          vmid: "listingScreen"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class Home extends Vue {
  checkedCrops: any = [];
  // value: string | null = null;
  title = "Crop";
  cropSlug: string = "";
  get cropId() {
    return CropVarietyStore.cropIdInList;
  }

  get selectedCompareCrop() {
    return CompareCropStore.selectedCrops;
  }

  set cropId(id: number) {
    CropVarietyStore.setCropIdInList(id);
  }

  get value() {
    return CropVarietyStore.Sorter;
  }

  set value(val: any) {
    CropVarietyStore.setSorter(val);
  }

  get loading() {
    return LoadStore.loadingCropVarieties;
  }

  sortVarieties() {
    let query = CropVarietyStore.Filter;
    CropVarietyStore.setPageNumber(1);
    if (this.value != null) {
      query.cropid = this.cropId;
      query.lang = this.$i18n.locale;
      query.sorter = this.value;
      query.page = 1;
    } else {
      query.cropid = this.cropId;
      query.lang = this.$i18n.locale;
      delete query.sorter;
      query.page = 1;
    }
    this.$router
      .replace({
        name: "ListingScreen",
        query: { page: String(1) },
        params: { cropSlug: String(this.cropSlug) }
      })
      .catch(error => {});
    CropVarietyStore.saveFilterQuery(query);
    CropVarietyStore.getAllCropVarietiesDetail(query);
  }

  get sortableVariables() {
    return CropVarietyStore.Variables;
  }

  private get crops() {
    return CropStore.CropNameList;
  }

  private get crop() {
    let crop = CropStore.Crop;
    this.title = crop.Name;
    return crop;
  }

  private get filterables() {
    return CropVarietyStore.CropVarietyFilterables;
  }

  private get varieties() {
    if (CropVarietyStore.CropVarietiesPaginated !== undefined) {
      return CropVarietyStore.CropVarietiesPaginated;
    }
  }

  async compareClick() {
    const checked = this.selectedCompareCrop;
    if (checked.length == 0) {
      CompareCropStore.toggleCheckbox();
      if (CompareCropStore.isHidden) {
        CompareCropStore.clearSelectedCrops();
      }
    } else if (checked.length >= 4) {
      this.$message({
        message: "Please select less than 3 items",
        type: "warning"
      });
    } else if (checked.length >= 2) {
      const cropsIds = checked.map(x => x.id);
      const cropsIdString = cropsIds.join(",");
      // await CompareCropStore.compareCropDetail(cropsIdString);

      this.$router
        .push({
          name: "comparescreen",
          query: { cropsIdString: String(cropsIdString) }
        })
        .catch(error => {});
      CompareCropStore.clearSelectedCrops();
    } else {
      this.$message({
        message: "Please select at least 2 items",
        type: "error"
      });
    }
  }

  getCropVarietyPath(cropVarietyId: number) {
    return this.$router.resolve({
      name: "VarietyDetail",
      query: { cropVarietyId: String(cropVarietyId) }
    }).href;
  }

  private async mounted() {
    this.cropSlug = this.$route.params.cropSlug;
    this.cropId = await MetaStore.getCropIdBySlug(this.cropSlug);
    let pageNum = 1;
    if (this.$route.query.page) {
      pageNum = Number(this.$route.query.page);
    }
    LoadStore.showLoadingCropVarieties();
    await CropStore.getCropById({ id: this.cropId, lang: this.$i18n.locale });
    await CropVarietyStore.getAllCropVarietiesDetail({
      cropid: String(this.cropId),
      page: String(pageNum),
      lang: this.$i18n.locale
    });
    LoadStore.hideLoadingCropVarieties();
    CropVarietyStore.getCropVarietyFilterables({
      cropId: this.cropId,
      lang: this.$i18n.locale
    });
    CropVarietyStore.getSortableVariables({
      cropId: this.cropId,
      lang: this.$i18n.locale
    });
  }
}
