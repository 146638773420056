

























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DemandSupplyStore } from "@/store/modules";
import SeedTypeDetail from "@/views/SupplierSearch/Components/SeedTypeDetail.vue";
import { MetaInfo } from "vue-meta";

@Component({
  components: { SeedTypeDetail },
  metaInfo(): MetaInfo {
    return {
      title: this.$data.title,
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: this.$data.title },
        {
          name: "description",
          content: "Detail of " + this.$data.title,
          vmid: "demandList"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class SupplierDetail extends Vue {
  userSlug: string = "";
  title: string = "";

  get profile() {
    return DemandSupplyStore.SupplierDetail.profile;
  }

  get preferences() {
    return DemandSupplyStore.SupplierDetail.preferences;
  }

  get dataList() {
    return DemandSupplyStore.SupplierDetail.availableseeds;
  }

  async created() {
    if (this.$route.params.userSlug) {
      this.userSlug = this.$route.params.userSlug;
    }
    await DemandSupplyStore.getSupplierBySlug(this.userSlug);
    this.title = this.profile.name;
  }
}
