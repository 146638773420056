














import { Vue, Component } from "vue-property-decorator";
import { CropStore, CropVarietyStore } from "@/store/modules";

@Component
export default class Varieties extends Vue {
  cropVarietyId: number = 0;

  private get cropVariety() {
    return CropVarietyStore.CropVariety;
  }

  private created() {
    this.cropVarietyId = Number(this.$route.query.cropVarietyId);
    CropVarietyStore.getCropVarietyById({ id: this.cropVarietyId });
  }
}
