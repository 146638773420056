




















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  DSADStore,
  MetaStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Advanced Supplier Search",
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: "Sqcc allocation list" },
        {
          name: "description",
          content: "List of Allocation of seeds",
          vmid: "demandList"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number | null = null;
  cropVarietyId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  supplierName: string | null = null;
  loading: boolean = false;
  detailVisible: boolean = false;
  allocation: any = {};

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return i;
  }

  get dataList() {
    return DemandSupplyStore.SupplierInfo;
  }

  get districts() {
    return MetaStore.District;
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get cropVarieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.cropId && this.cropId !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.cropId);
    }
    return varieties;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  changeCrop() {
    this.cropVarietyId = null;
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  viewDetail(slug: string) {
    this.$router
      .push({
        name: "SupplierDetail",
        params: {
          userSlug: slug
        }
      })
      .catch(error => {});
  }

  search() {
    let query: any = { page: 1 };
    if (this.cropId != null) {
      query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    if (this.seedTypeId != null) {
      query["seedTypeId"] = String(this.seedTypeId);
    }
    if (this.districtId != null) {
      query["districtId"] = String(this.districtId);
    }
    if (this.supplierName != null) {
      query["supplierName"] = String(this.supplierName);
    }
    this.routerReplacer();
    DemandSupplyStore.getSupplierInfo(query);
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.cropId = null;
    this.cropVarietyId = null;
    this.seedTypeId = null;
    this.districtId = null;
    this.supplierName = null;
    this.routerReplacer();
    DemandSupplyStore.getSupplierInfo(query);
  }

  handlePageChange(val: any) {
    this.pageNum = val;
    this.routerReplacer();
    DemandSupplyStore.getSupplierInfo({ page: val });
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.cropId != null) {
      query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    if (this.seedTypeId != null) {
      query["seedTypeId"] = String(this.seedTypeId);
    }
    if (this.districtId != null) {
      query["districtId"] = String(this.districtId);
    }
    if (this.supplierName != null) {
      query["supplierName"] = String(this.supplierName);
    }
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  private async created() {
    MetaStore.getAllDistrict();
    CropStore.getAllCropName();
    CropVarietyStore.getAllCropVariety();
    DemandSupplyStore.getAllSeedType({ lang: this.$i18n.locale });
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }

    if (this.$route.query.cropId) {
      this.cropId = Number(this.$route.query.cropId);
    }

    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }
    if (this.$route.query.supplierName) {
      this.supplierName = String(this.$route.query.supplierName);
    }
    let query = this.routerReplacer();
    DemandSupplyStore.getSupplierInfo(query);
  }
}
