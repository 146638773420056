



































import { Vue, Component } from "vue-property-decorator";
import { CropStore, CropVarietyStore } from "@/store/modules";

@Component
export default class Varieties extends Vue {
  cropId: number = 0;

  private get crop() {
    return CropStore.Crop;
  }

  private get varieties() {
    return CropVarietyStore.CropVarieties;
  }

  getCropVarietyPath(cropVarietyId: number) {
    return this.$router.resolve({
      name: "VarietyDetail",
      query: { cropVarietyId: String(cropVarietyId) }
    }).href;
  }

  private created() {
    this.cropId = Number(this.$route.query.cropId);
    CropStore.getCropById({ id: this.cropId, lang: this.$i18n.locale });
    CropVarietyStore.getAllCropVarieties({ cropid: String(this.cropId) });
  }
}
