
































































import { Vue, Component } from "vue-property-decorator";
import Detailinfo from "@/components/UIComponents/Detailinfo.vue";
import Varities from "@/components/UIComponents/Varities.vue";
import {
  CropStore,
  CropVarietyStore,
  UserStore,
  LoadStore,
  MetaStore,
  CommentStore
} from "@/store/modules";
import Share from "@/components/UIComponents/Share.vue";
import { MetaInfo } from "vue-meta";
import Comments from "@/components/UIComponents/Comments.vue";

@Component({
  components: {
    Varities,
    Detailinfo,
    Share,
    comments: Comments
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$data.title,
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: this.$data.title },
        {
          name: "description",
          content: "Detail of " + this.$data.title,
          vmid: "detailScreen"
        },
        {
          property: "og:image",
          content: this.$data.image,
          vmid: "og:image"
        },
        {
          property: "og:title",
          content: this.$data.title,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: "Detail of " + this.$data.title,
          vmid: "og:description"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class DetailScreen extends Vue {
  cropSlug: string = "";
  cropVarietySlug: string = "";
  cropVarietyId: number = 0;
  pageNum: number = 1;
  title = "Crop Variety";
  image = "https://seedapi.sqcc.gov.np/media/CropVarietyImages/bar.JPG";
  loadingVarieties: boolean = false;
  get loading() {
    return LoadStore.loadingCropVarietyDetail;
  }
  // images: string[] = [];

  private get currentURL() {
    // console.log
    return window.location.href;
  }

  private get cropVariety() {
    let cropVariety = CropVarietyStore.CropVariety;
    this.title = cropVariety.name;
    let images = cropVariety.imagearray;
    if (images && images.length > 0) {
      let image = images.filter(x => x.default == true);
      if (image.length > 0 && image[0] && image[0].url) {
        this.image = image[0].url;
      }
    }
    return cropVariety;
  }

  get suppliers() {
    return CropVarietyStore.SupplierCropVariety;
  }

  async exportPdf() {
    let data = (await CropVarietyStore.exportPdf({
      id: this.cropVarietyId,
      lang: this.$i18n.locale
    })) as any;
    if (data.url != undefined) {
      var link = document.createElement("a");
      link.setAttribute("href", data.url);
      link.setAttribute("target", "_blank");
      link.click();
    }
  }

  private async created() {
    (window as any).prerenderReady = false;
    this.cropVarietySlug = this.$route.params.varietySlug;
    this.cropSlug = this.$route.params.cropSlug;
    this.cropVarietyId = await MetaStore.getCropVarietyIdBySlug(this.cropVarietySlug);
    let params = {};
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    params = {
      varietyId: this.cropVarietyId,
      page: this.pageNum
    };
    CropVarietyStore.getSupplierCropVariety(params);

    LoadStore.showLoadingCropVarietyDetail();
    let data: { name: string; image: string } = await CropVarietyStore.preRenderCropVariableDetail({
      id: this.cropVarietyId,
      lang: this.$i18n.locale
    });
    this.title = data["name"];
    this.image = data["image"];
    (window as any).prerenderReady = true;

    CropVarietyStore.getImagesByVarietyId(this.cropVarietyId);
    // UserStore.getUser();
    CommentStore.getCommentsByCropVarietyId(this.cropVarietyId);
    CommentStore.getRepliesByCropVarietyId(this.cropVarietyId);

    this.loadingVarieties = true;
    await CropVarietyStore.getCropVarietyDetailById({
      id: this.cropVarietyId,
      lang: this.$i18n.locale
    });
    LoadStore.hideLoadingCropVarietyDetail();

    await CropVarietyStore.getSimilarCropVariety({
      cropId: this.cropVarietyId,
      lang: this.$i18n.locale
    });
    this.loadingVarieties = false;
    // this.loadImages();
    // console.log(typeof this.images[0]);
    // document
    //   .getElementsByClassName("qwert")[0]
    //   .setAttribute("property", "og:image");
    // document
    //   .getElementsByClassName("qwert")[0]
    //   .setAttribute("content", this.images[0]);
  }

  // private mounted() {
  //   this.cropVarietyId = Number(this.$route.query.varietyId);
  //   CropVarietyStore.getCropVarietyById(this.cropVarietyId);
  //   CropVarietyStore.getSimilarCropVariety(this.cropVarietyId);
  //   this.loadImages();
  // }

  // async loadImages() {
  //   this.images = await CropVarietyStore.getImagesByVarietyId(
  //     this.cropVarietyId
  //   );
  // }

  // get images() {
  //   return CropVarietyStore.Images;
  // }

  get captions() {
    return CropVarietyStore.ImageCaptions;
  }
}
