






































































































import { Component, Vue } from "vue-property-decorator";
import { CompareCropStore } from "@/store/modules";
import { DownloadCompareCrops } from "@/store/models/cropVariety";

@Component
export default class CompareChanges extends Vue {
  loading: boolean = true;
  exporting: boolean = false;
  download: DownloadCompareCrops = {} as DownloadCompareCrops;

  private get CompareCrop() {
    return CompareCropStore.CropVarieties;
  }

  DownloadCompareCrops() {
    return CompareCropStore.DownloadCropVarieties;
  }

  async downloadCompareVariety() {
    let cropIdString = this.$route.query.cropsIdString;
    this.exporting = true;
    await CompareCropStore.downloadCompareCropDetail({
      ids: cropIdString,
      lang: this.$i18n.locale,
      download: true
    });
    this.download = this.DownloadCompareCrops();
    if (this.download.url !== undefined) {
      var link = document.createElement("a");
      link.setAttribute("href", this.download.url);
      link.setAttribute("target", "_blank");
      link.click();
    }
    this.exporting = false;
  }

  private async created() {
    this.loading = true;
    let cropIdString = this.$route.query.cropsIdString;
    await CompareCropStore.compareCropDetail({
      ids: cropIdString,
      lang: this.$i18n.locale
    });
    this.loading = false;
  }
  private get Crop() {
    let crop: any = {
      croptypeid: 0,
      croptypename: ""
    } as any;
    if (this.CompareCrop != undefined && this.CompareCrop["Meta"] !== undefined) {
      crop = this.CompareCrop["Meta"][0];
    }
    return crop;
  }
}
